.judo-sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 101;
  top: 0;
  bottom: 0;

  .last-element {
    width: 100%  !important;
    position: absolute !important;
    bottom: 50px  !important;
    margin: 0  !important;
    //margin-top: 0  !important;
  }

  &.is-responsive {
    .ant-layout-sider-trigger {
      display: none;
    }

    .last-element {
      bottom: 0;
    }
  }

  .logo {
    height: 55px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      background-color: white;
    }

    img:hover {
      cursor: pointer;
    }
  }
}


