.login-form {
  width: 100%;
  max-width: 600px;

  .label {
    font-weight: bold;
  }

  .label-row {
    margin-bottom: 5px;
  }

  .input-row {
    margin-bottom: 15px;
  }

  .login-button {
    margin-top: 5px;
    width: 100%;
  }
}