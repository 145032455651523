body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.anticon svg {
  display: block !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-bottom-5-col {
  [class^=col] {
    margin-bottom: 5px;
  }
}

.center-column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-vertical-col {
  display: flex;
  align-items: center;
}

.ant-card:not(:last-child) {
  margin-bottom: 10px;
}

.cms-card {
  min-height: calc(100vh - 75px);
}

.cms-form {
  width: 100%;
  max-width: 700px;

  .label {
    font-weight: bold;
  }

  .label-row {
    margin-bottom: 5px;
  }

  .input-row {
    margin-bottom: 15px;
  }

  .submit-button {
    margin-top: 5px;
    width: 100%;
  }
}

.cms-selector {
  .select {
    width: 100%;
  }
}

.success-modal {
  .ant-modal-confirm-body {
    display: block !important;
    text-align: center !important;
    min-height: 80px !important;

    & > .anticon {
      margin-bottom: 5px;
      font-size: 35px;
      color: #52c41a !important;
    }
  }
}
