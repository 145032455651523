.judo-layout {
  min-height: 100vh;
  overflow: hidden;

  .judo-content-div {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .judo-header {
    height: 55px;
    position: fixed;
    z-index: 100;
    width: 100%;
    background: #001529;
  }

  .judo-content-inner-div {
    padding: 10px;
  }
}
